<template>
  <div class="index">
    <div class="xx index-1" :style="style">
      <div v-if="countdown_time">
        <div
          :style="{ backgroundImage: 'url(' + image + ')' }"
          class="bg index-2"
        >
          <div class="title">小程序 预约/排队，享折上折优惠</div>
          <div class="grid" style="margin-top: 59px">
            <div style="color: #ffd19c; width: 40%; font-size: 62px">项目</div>
            <div
              style="
                margin: 0 auto;
                width: 20%;
                display: flex;
                justify-content: center;
                font-size: 52px;
              "
            >
              <div
                class="btn_img"
                :style="{ backgroundImage: 'url(' + btn_img + ')' }"
              >
                会员
              </div>
            </div>
            <div
              style="
                margin: 0 auto;
                width: 40%;
                display: flex;
                justify-content: center;
                font-size: 52px;
              "
            >
              <div
                class="btn_img"
                :style="{ backgroundImage: 'url(' + btn_img + ')' }"
              >
                非会员
              </div>
            </div>
          </div>
          <div
            v-for="(item, index) in classify"
            :key="index"
            class="grid"
            style="color: #ffd19c"
          >
            <div style="width: 40%; font-size: 66px">
              {{ item.classify_name }}
            </div>
            <div style="font-size: 100px; width: 20%">
              {{
                item.member_default_price
                  ? parseFloat(
                      item.member_default_price * 1 -
                        item.member_default_back_price * 1
                    )
                  : '-'
              }}
            </div>
            <div style="font-size: 100px; width: 40%">
              <template v-if="item.price_money">
                <span class="price">{{ parseFloat(item.price_money) }}</span
                >{{ parseFloat(item.back_money) }}
                <!-- <span class="price">999</span>299 -->
              </template>
              <template v-else>-</template>
            </div>
          </div>

          <!--  -->
          <div
            style="
              width: 320px;
              height: 320px;
              border-radius: 50%;
              background: #fff;
              margin: 0 auto;
              margin-top: 60px;
            "
          >
            <img
              style="width: 100%; height: 100%; border-radius: 50%"
              :src="code_url"
              alt=""
              srcset=""
            />
          </div>
          <!--  -->
          <div
            :style="{ backgroundImage: 'url(' + time_bg + ')' }"
            style="
              display: flex;
              justify-content: center;
              margin: 0 auto;
              margin-top: 40px;
              width: 700px;
              height: 60px;
              line-height: 60px;
            "
          >
            <van-count-down
              v-if="countdown_time"
              ref="countDown"
              @finish="finish"
              :time="countdown_time * 1000 - Date.parse(new Date())"
            >
              <template #default="timeData">
                <div style="color: #ffd19c; font-size: 40px; line-height: 60px">
                  {{ timeData.minutes }}分钟{{ timeData.seconds }}秒后价格更新
                </div>
              </template>
            </van-count-down>
          </div>
          <!--  -->
          <div
            style="
              color: #af9660;
              font-size: 30px;
              padding: 0 65px;
              margin: 0 auto;
              margin-top: 40px;
              line-height: 40px;
            "
          >
            1、以上优惠必须扫此二维码小程序下单才可享受<br />
            2、新老用户同价<br />
            3、展示价格为当前价格，每半小时价格会根据店内闲忙情况有所变化<br />
            4、以上价格为同等级发型师服务的最低价，多个发型师可能价格不同。<br />
            5、返还的储值可以退款，但每年仅可退款一次<br />
            6、以上优惠可以和充值卡优惠叠加使用<br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const width = 1080
const height = 1920
export default {
  data() {
    return {
      time: 0,
      image: require('@/assets/WechatIMG3723.jpeg'),
      btn_img: require('@/assets/jx_rh.png'),
      time_bg: require('@/assets/time_bg.png'),
      style: {
        width: width + 'px',
        height: height + 'px',
        transform: 'scale(1) translate(-50%,-50%)',
      },
      info: {},
      val: null,
    }
  },
  computed: {
    countdown_time() {
      return this.info?.countdown_time ?? 0
    },
    code_url() {
      return this.info?.code_url ?? ''
    },
    classify() {
      return this.info?.classify ?? []
    },
  },
  mounted() {
    window.onresize = this.reSize
    this.reSize()
    this.getDate()
    this.val = setInterval(() => {
      this.getDate()
    }, 60 * 1000)
  },
  destroyed() {
    clearInterval(this.val)
  },
  methods: {
    getScale() {
      const w = window.innerWidth / width
      const h = window.innerHeight / height
      return w - h > 0 ? h : w
    },
    reSize() {
      this.style.transform = `scale(${this.getScale()}) translate(-50%,-50%)`
    },
    async getDate() {
      let { data } = await this.$api.post('Index/getH5Home')

      this.info = data
    },
    finish() {
      this.$refs.countDown.reset()
      this.getDate()
    },
  },
}
</script>

<style scoped>
.xx {
  transform-origin: 0% 0%;
  position: absolute;
  left: 50%;
  top: 50%;
}

.bg {
  background-size: 100% 100%;
}

.index {
  position: relative;
  height: 100vh;
  background: #000;
  font-weight: normal;
  overflow: hidden;
}
.title {
  font-family: Medium;
  font-size: 70px;
  color: #ffd5a5;
  text-align: center;
  padding-top: 70px;
}
.index-1 {
  margin: 0 auto;
  overflow: hidden;
}
.index-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-size: 100% 100%;
}
.btn_img {
  color: #000;
  background-size: 100% 100%;
  width: 226px;
  height: 62px;
  line-height: 62px;
}
.price::after {
  content: '返';
  font-size: 60%;

  color: #ffd19c;
}
.grid {
  display: flex;
  align-items: center;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr; */
  text-align: center;
  font-size: 40px;
  /* margin-top: 33px; */
  height: 151px;
}
</style>
